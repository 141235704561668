import * as React from "react";
import { GlobalStyle } from "../assets/styles/globalStyles";
import {
	StyledSection,
	StyledSectionInner,
} from "../assets/styles/commonStyles";
import { carsRowTop } from "../data/dummy-data";
import SelectModel from "../components/common/SelectModel";
import SliderCustom from "../components/common/SliderCustom";
import Layout from "../components/common/Layout";

const SliderCustomPage = () => {
	return (
		<Layout>
			<main>
				<GlobalStyle />

				<StyledSection>
					<StyledSectionInner noPaddingTopBot={true}>
						<div>
							<SelectModel />
							<SliderCustom items={carsRowTop} />
						</div>
					</StyledSectionInner>
				</StyledSection>
			</main>
		</Layout>
	);
};

export default SliderCustomPage;
