// SELECT
export const AudiEtron = [
	{ value: "e-tron", label: "E-tron" },
	{
		value: "e-tron-sportback",
		label: "E-tron Sportback",
	},
	{ value: "audi-e-tron-s", label: "Audi e-tron S" },
	{
		value: "audi-e-tron-s-sportback",
		label: "Audi e-tron S Sportback",
	},
];

export const AudiA1 = [
	{ value: "e-tron", label: "E-tron" },
	{
		value: "e-tron-sportback",
		label: "E-tron Sportback",
	},
	{ value: "audi-e-tron-s", label: "Audi e-tron S" },
	{
		value: "audi-e-tron-s-sportback",
		label: "Audi e-tron S Sportback",
	},
];

// CAR SLIDER
export const carsRowTop = [
	{
		id: 1,
		name: "Audi e-tron",
		desc: "Desde 72.990€",
		image: "/images/car-01-1.webp",
		imageHover: "/images/car-01-2.webp",
	},
	{
		id: 2,
		name: "Audi A1",
		desc: "Desde 21.410€",
		image: "/images/car-02-1.webp",
		imageHover: "/images/car-02-2.webp",
	},
	{
		id: 3,
		name: "Audi A3",
		desc: "Desde 27.760€",
		image: "/images/car-03-1.webp",
		imageHover: "/images/car-03-2.webp",
	},
	{
		id: 4,
		name: "Audi A4",
		desc: "Desde 28.740€",
		image: "/images/car-04-1.webp",
		imageHover: "/images/car-04-2.webp",
	},
	{
		id: 5,
		name: "Audi A5",
		desc: "Desde 29.740€",
		image: "/images/car-05-1.webp",
		imageHover: "/images/car-05-2.webp",
	},
	{
		id: 6,
		name: "Audi A6",
		desc: "Desde 32.367€",
		image: "/images/car-06-1.webp",
		imageHover: "/images/car-06-2.webp",
	},
	{
		id: 7,
		name: "Audi e-tron",
		desc: "Desde 72.990€",
		image: "/images/car-01-1.webp",
		imageHover: "/images/car-01-2.webp",
	},
	{
		id: 8,
		name: "Audi A1",
		desc: "Desde 21.410€",
		image: "/images/car-02-1.webp",
		imageHover: "/images/car-02-2.webp",
	},
	{
		id: 9,
		name: "Audi A3",
		desc: "Desde 27.760€",
		image: "/images/car-03-1.webp",
		imageHover: "/images/car-03-2.webp",
	},
	{
		id: 10,
		name: "Audi A4",
		desc: "Desde 28.740€",
		image: "/images/car-04-1.webp",
		imageHover: "/images/car-04-2.webp",
	},
	{
		id: 11,
		name: "Audi A5",
		desc: "Desde 29.740€",
		image: "/images/car-05-1.webp",
		imageHover: "/images/car-05-2.webp",
	},
	{
		id: 12,
		name: "Audi A6",
		desc: "Desde 32.367€",
		image: "/images/car-06-1.webp",
		imageHover: "/images/car-06-2.webp",
	},
];
export const carsRowBot = [
	{
		id: 1,
		name: "Nuevo Audi A3 Sportback",
		desc: "Desde 27.760€",
		image: "/images/car-2-1.webp",
	},
	{
		id: 2,
		name: "Nuevo Audi A3 Sedan",
		desc: "Desde 28.760€",
		image: "/images/car-2-2.webp",
	},
	{
		id: 3,
		name: "Nuevo Audi S3 Sportback",
		desc: "Desde 30.760€",
		image: "/images/car-2-3.webp",
	},
	{
		id: 4,
		name: "Nuevo Audi S3 Sedan",
		desc: "Desde 31.760€",
		image: "/images/car-2-4.webp",
	},
	{
		id: 5,
		name: "Nuevo Audi A3 Sportback TFSIe",
		desc: "Desde 37.760€",
		image: "/images/car-2-5.webp",
	},
];

// TEXT SLIDER
export const itemsTextSlider = [
	{
		id: 1,
		text:
			"Si tu Audi A3 Sportback está en stock consigue una promoción especial desde 2.837€",
		bgImg: "/images/text-slider-01.jpg",
		buttons: [
			{
				text: "Solicitar Oferta",
				url: "https://www.audi.es/es/web/es.html",
			},
			{
				text: "Ver Stock",
				url: "https://www.audi.es/es/web/es.html",
			},
		],
	},
	{
		id: 1,
		text: "Si tu Audi A4 Sportback está en stock",
		buttons: [
			{
				text: "Solicitar Oferta",
				url: "https://www.audi.es/es/web/es.html",
			},
		],
	},
];

// PROMOCIONES SLIDER
export const itemsPromocionesSlider = [
	{
		id: 1,
		title: "Renting",
		desc:
			"Un Renting es un alquiler con cómodas cuotas que suele incluir \n" +
			"el mantenimiento, seguro a todo riesgo y asistencia en carretera 24 horas.",
		infoExtra: {
			label: "Por",
			value: "220€/mes",
		},
		buttons: [
			{
				text: "Sabes más",
				url: "https://www.audi.es/es/web/es.html",
			},
		],
	},
	{
		id: 2,
		title: "Financiación",
		desc:
			"Compra tu Audi de la forma tradicional y benefíciate de una promoción especial por financiarlo con Audi Financial Services.",
		infoExtra: {
			label: "Desde",
			value: "27.760€",
		},
		buttons: [
			{
				text: "Sabes más",
				url: "https://www.audi.es/es/web/es.html",
			},
		],
	},
	{
		id: 3,
		title: "Audi Opción",
		desc:
			"Adquiere tu Audi con la posibilidad de devolverlo en el futuro con un valor de recompra asegurado por Audi.",
		infoExtra: {
			label: "Por",
			value: "320€/mes",
		},
		buttons: [
			{
				text: "Sabes más",
				url: "https://www.audi.es/es/web/es.html",
			},
		],
	},
	{
		id: 4,
		title: "Vehículos en stock",
		desc:
			"Elige uno de nuestros vehículos en stock y empieza a conducirlo de forma inmediata y con el mejor precio.",
		infoExtra: {
			label: "Desde",
			value: "18.000€",
		},
		buttons: [
			{
				text: "Vehículos nuevos",
				url: "#",
			},
			{
				text: "Vehículos ocasión",
				url: "#",
			},
		],
	},
];
export const itemsPromocionesSliderDos = [
	{
		id: 1,
		title: "Renting",
		desc:
			"Un Renting es un alquiler con cómodas cuotas que suele incluir \n" +
			"el mantenimiento, seguro a todo riesgo y asistencia en carretera 24 horas.",
		infoExtra: {
			label: "Por",
			value: "220€/mes",
		},
		buttons: [
			{
				text: "Sabes más",
				url: "https://www.audi.es/es/web/es.html",
			},
		],
	},
	{
		id: 2,
		title: "Financiación",
		desc:
			"Compra tu Audi de la forma tradicional y benefíciate de una promoción especial por financiarlo con Audi Financial Services.",
		infoExtra: {
			label: "Desde",
			value: "27.760€",
		},
		buttons: [
			{
				text: "Sabes más",
				url: "https://www.audi.es/es/web/es.html",
			},
		],
	},
	{
		id: 3,
		title: "Audi Opción",
		desc:
			"Adquiere tu Audi con la posibilidad de devolverlo en el futuro con un valor de recompra asegurado por Audi.",
		infoExtra: {
			label: "Por",
			value: "320€/mes",
		},
		buttons: [
			{
				text: "Sabes más",
				url: "https://www.audi.es/es/web/es.html",
			},
		],
	},
	{
		id: 4,
		title: "Vehículos en stock",
		desc:
			"Elige uno de nuestros vehículos en stock y empieza a conducirlo de forma inmediata y con el mejor precio..",
		infoExtra: {
			label: "Disponibles",
			value: "36",
		},
		buttons: [
			{
				text: "Ver stock",
				url: "#",
			},
		],
	},
];

// PROMOCIONES MODELO SLIDER
export const itemsPromocionesModeloSlider = [
	{
		id: 1,
		image: "/images/promotion-model-slider-img.png",
		preTitle: "Nuevo Audi A3 Sportback",
		title: "Genuine edition",
		price: "290€",
		priceNote: "Con entrada. IVA incluido",
		featuresList: [
			{
				value: "Renting a 36 meses.",
			},
			{
				value: "Todos los servicios incluidos.",
			},
			{
				value: "Equipamiento exclusivo.",
			},
		],
		buttonText: "Saber más",
		buttonUrl: "https://www.audi.es/es/web/es.html",
	},
	{
		id: 2,
		image: "/images/promotion-model-slider-img.png",
		preTitle: "Nuevo Audi A3 Sportback 2",
		title: "Genuine edition",
		price: "590€",
		priceNote: "Con entrada. IVA incluido",
		featuresList: [
			{
				value: "Renting a 36 meses.",
			},
			{
				value: "Todos los servicios incluidos.",
			},
			{
				value: "Equipamiento exclusivo.",
			},
		],
		buttonText: "Saber más",
		buttonUrl: "https://www.audi.es/es/web/es.html",
	},
];

// MODELOS DETALLE SLIDER
export const itemsModelosDetalleSlider = [
	{
		id: 1,
		image: "/images/basico.png",
		title: "Basic",
		pvp: "27.760€",
		financiacion: {
			price: "-1500€",
			description:
				"Financia con Audi Financial Services hasta el 100% del valor del vehículo y consigue una promoción de <strong>-1500€ a descontar del precio final.</strong>",
		},
		button: {
			text: "Solicitar oferta",
			url: "#",
		},
		featuresList: [
			{
				title: "Equipamiento de serie ",
				infoList: [
					{
						value:
							"Faros LED y grupos ópticos traseros LED con intermitentes dinámicos en la parte trasera",
					},
					{
						value:
							"Llantas de aleación ligera, radios múltiples, 8,0J x 17, neumáticos 225/45",
					},
					{
						value:
							"Umbrales de las puertas con inserciones en aluminio en la puerta delantera",
					},
				],
			},
		],
	},
	{
		id: 2,
		image: "/images/advance.png",
		title: "Advanced",
		pvp: "28.320€",
		financiacion: {
			price: "-1500€",
			description:
				"Financia con Audi Financial Services hasta el 100% del valor del vehículo y consigue una promoción de <strong>-1500€ a descontar del precio final.</strong>",
		},
		button: {
			text: "Solicitar oferta",
			url: "#",
		},
		featuresList: [
			{
				title: "Equipamiento de serie ",
				infoList: [
					{
						value:
							"Faros LED y grupos ópticos traseros LED con intermitentes dinámicos en la parte trasera",
					},
					{
						value:
							"Llantas de aleación ligera, radios múltiples, 8,0J x 17, neumáticos 225/45",
					},
					{
						value:
							"Umbrales de las puertas con inserciones en aluminio en la puerta delantera",
					},
				],
			},
			{
				title: "Equipamiento de línea",
				infoList: [
					{
						value:
							"Faros LED y grupos ópticos traseros LED con intermitentes dinámicos en la parte trasera",
					},
					{
						value:
							"Llantas de aleación ligera, radios múltiples, 8,0J x 17, neumáticos 225/45",
					},
					{
						value:
							"Umbrales de las puertas con inserciones en aluminio en la puerta delantera",
					},
				],
			},
		],
	},
	{
		id: 3,
		image: "/images/ge.png",
		title: "Genuine edition ",
		pvp: "28.958€",
		financiacion: {
			price: "-1500€",
			description:
				"Financia con Audi Financial Services hasta el 100% del valor del vehículo y consigue una promoción de <strong>-1500€ a descontar del precio final.</strong>",
		},
		button: {
			text: "Solicitar oferta",
			url: "#",
		},
		featuresList: [
			{
				title: "Equipamiento de serie ",
				infoList: [
					{
						value:
							"Faros LED y grupos ópticos traseros LED con intermitentes dinámicos en la parte trasera",
					},
					{
						value:
							"Llantas de aleación ligera, radios múltiples, 8,0J x 17, neumáticos 225/45",
					},
					{
						value:
							"Umbrales de las puertas con inserciones en aluminio en la puerta delantera",
					},
				],
			},
			{
				title: "Equipamiento de línea",
				infoList: [
					{
						value: "Spoiler del borde del techo S",
					},
					{
						value:
							"Molduras estriberas con inserciones en aluminio delante, con iluminación, con inscripción S",
					},
					{
						value: "Paquete exterior S line",
					},
					{
						value:
							"Rejilla del Audi Single Frame pintada en negro titano con marco cromado",
					},
				],
			},
		],
	},
	{
		id: 4,
		image: "/images/sline.png",
		title: "S line",
		pvp: "29.267€",
		financiacion: {
			price: "-1500€",
			description:
				"Financia con Audi Financial Services hasta el 100% del valor del vehículo y consigue una promoción de <strong>-1500€ a descontar del precio final.</strong>",
		},
		button: {
			text: "Solicitar oferta",
			url: "#",
		},
		featuresList: [
			{
				title: "Equipamiento de serie ",
				infoList: [
					{
						value:
							"Faros LED y grupos ópticos traseros LED con intermitentes dinámicos en la parte trasera",
					},
					{
						value:
							"Llantas de aleación ligera, radios múltiples, 8,0J x 17, neumáticos 225/45",
					},
					{
						value:
							"Umbrales de las puertas con inserciones en aluminio en la puerta delantera",
					},
				],
			},
			{
				title: "Equipamiento de línea",
				infoList: [
					{
						value: "Spoiler del borde del techo S",
					},
					{
						value:
							"Molduras estriberas con inserciones en aluminio delante, con iluminación, con inscripción S",
					},
					{
						value: "Paquete exterior S line",
					},
					{
						value:
							"Rejilla del Audi Single Frame pintada en negro titano con marco cromado",
					},
				],
			},
		],
	},
	{
		id: 5,
		image: "/images/basico.png",
		title: "Basic",
		pvp: "27.760€",
		financiacion: {
			price: "-1500€",
			description:
				"Financia con Audi Financial Services hasta el 100% del valor del vehículo y consigue una promoción de <strong>-1500€ a descontar del precio final.</strong>",
		},
		button: {
			text: "Solicitar oferta",
			url: "#",
		},
		featuresList: [
			{
				title: "Equipamiento de serie ",
				infoList: [
					{
						value:
							"Faros LED y grupos ópticos traseros LED con intermitentes dinámicos en la parte trasera",
					},
					{
						value:
							"Llantas de aleación ligera, radios múltiples, 8,0J x 17, neumáticos 225/45",
					},
					{
						value:
							"Umbrales de las puertas con inserciones en aluminio en la puerta delantera",
					},
				],
			},
		],
	},
	{
		id: 6,
		image: "/images/advance.png",
		title: "Basic",
		pvp: "27.760€",
		financiacion: {
			price: "-1500€",
			description:
				"Financia con Audi Financial Services hasta el 100% del valor del vehículo y consigue una promoción de <strong>-1500€ a descontar del precio final.</strong>",
		},
		button: {
			text: "Solicitar oferta",
			url: "#",
		},
		featuresList: [
			{
				title: "Equipamiento de serie ",
				infoList: [
					{
						value:
							"Faros LED y grupos ópticos traseros LED con intermitentes dinámicos en la parte trasera",
					},
					{
						value:
							"Llantas de aleación ligera, radios múltiples, 8,0J x 17, neumáticos 225/45",
					},
					{
						value:
							"Umbrales de las puertas con inserciones en aluminio en la puerta delantera",
					},
				],
			},
			{
				title: "Equipamiento de línea",
				infoList: [
					{
						value:
							"Faros LED y grupos ópticos traseros LED con intermitentes dinámicos en la parte trasera",
					},
					{
						value:
							"Llantas de aleación ligera, radios múltiples, 8,0J x 17, neumáticos 225/45",
					},
					{
						value:
							"Umbrales de las puertas con inserciones en aluminio en la puerta delantera",
					},
				],
			},
		],
	},
	{
		id: 7,
		image: "/images/ge.png",
		title: "Genuine edition ",
		pvp: "28.958€",
		financiacion: {
			price: "-1500€",
			description:
				"Financia con Audi Financial Services hasta el 100% del valor del vehículo y consigue una promoción de <strong>-1500€ a descontar del precio final.</strong>",
		},
		button: {
			text: "Solicitar oferta",
			url: "#",
		},
		featuresList: [
			{
				title: "Equipamiento de serie ",
				infoList: [
					{
						value:
							"Faros LED y grupos ópticos traseros LED con intermitentes dinámicos en la parte trasera",
					},
					{
						value:
							"Llantas de aleación ligera, radios múltiples, 8,0J x 17, neumáticos 225/45",
					},
					{
						value:
							"Umbrales de las puertas con inserciones en aluminio en la puerta delantera",
					},
				],
			},
			{
				title: "Equipamiento de línea",
				infoList: [
					{
						value: "Spoiler del borde del techo S",
					},
					{
						value:
							"Molduras estriberas con inserciones en aluminio delante, con iluminación, con inscripción S",
					},
					{
						value: "Paquete exterior S line",
					},
					{
						value:
							"Rejilla del Audi Single Frame pintada en negro titano con marco cromado",
					},
				],
			},
		],
	},
	{
		id: 8,
		image: "/images/sline.png",
		title: "S line",
		pvp: "29.267€",
		financiacion: {
			price: "-1500€",
			description:
				"Financia con Audi Financial Services hasta el 100% del valor del vehículo y consigue una promoción de <strong>-1500€ a descontar del precio final.</strong>",
		},
		button: {
			text: "Solicitar oferta",
			url: "#",
		},
		featuresList: [
			{
				title: "Equipamiento de serie ",
				infoList: [
					{
						value:
							"Faros LED y grupos ópticos traseros LED con intermitentes dinámicos en la parte trasera",
					},
					{
						value:
							"Llantas de aleación ligera, radios múltiples, 8,0J x 17, neumáticos 225/45",
					},
					{
						value:
							"Umbrales de las puertas con inserciones en aluminio en la puerta delantera",
					},
				],
			},
			{
				title: "Equipamiento de línea",
				infoList: [
					{
						value: "Spoiler del borde del techo S",
					},
					{
						value:
							"Molduras estriberas con inserciones en aluminio delante, con iluminación, con inscripción S",
					},
					{
						value: "Paquete exterior S line",
					},
					{
						value:
							"Rejilla del Audi Single Frame pintada en negro titano con marco cromado",
					},
				],
			},
		],
	},
];

// MODELOS DETALLE GRID
export const itemsModelosDetallesGrid = [
	{
		id: 1,
		image: "/images/modelo-detalle-01.png",
		preTitle: "",
		title: "Promo Renting 1",
		price: "Desde 210€/mes",
		priceNote: "Con entrada. IVA incluido",
		model: "Advanced 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Servicios incluidos",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
	{
		id: 2,
		image: "/images/modelo-detalle-02.png",
		preTitle: "",
		title: "Promo Renting 2",
		price: "Desde 210€/mes",
		priceNote: "Con entrada. IVA incluido",
		model: "Advanced 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
			{
				text: "Contratar online",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Servicios incluidos",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
	{
		id: 3,
		image: "/images/modelo-detalle-03.png",
		preTitle: "",
		title: "Promo Renting 3",
		price: "Desde 210€/mes",
		priceNote: "Con entrada. IVA incluido",
		model: "Advanced 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Servicios incluidos",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
	{
		id: 4,
		image: "/images/modelo-detalle-01.png",
		preTitle: "",
		title: "Promo Renting 1",
		price: "Desde 210€/mes",
		priceNote: "Con entrada. IVA incluido",
		model: "Advanced 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Servicios incluidos",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
	{
		id: 5,
		image: "/images/modelo-detalle-02.png",
		preTitle: "",
		title: "Promo Renting 2",
		price: "Desde 210€/mes",
		priceNote: "Con entrada. IVA incluido",
		model: "Advanced 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
			{
				text: "Contratar online",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Servicios incluidos",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
	{
		id: 6,
		image: "/images/modelo-detalle-03.png",
		preTitle: "",
		title: "Promo Renting 3",
		price: "Desde 210€/mes",
		priceNote: "Con entrada. IVA incluido",
		model: "Advanced 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Servicios incluidos",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
];

export const itemsModelosDetallesGridDos = [
	{
		id: 1,
		image: "/images/modelo-detalle-01.png",
		preTitle: "",
		title: "Promo Renting 1",
		price: "Desde 210€/mes",
		priceNote: "Con entrada. IVA incluido",
		model: "Advanced 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Servicios incluidos",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			/*
      {
        title: "Condiciones",
        infoList: [
          {
            value: "Asistencia en carretera.",
          },
          {
            value: "Seguro a todo riesgo sin franquicia.",
          },
          {
            value: "Mantenimiento en Red Oficial Audi.",
          },
          {
            value: "Reparaciones con recambios originales.",
          },
        ],
      },

       */
		],
	},
	{
		id: 2,
		image: "/images/modelo-detalle-02.png",
		preTitle: "",
		title: "Promo Renting 2",
		price: "Desde 210€/mes",
		priceNote: "Con entrada. IVA incluido",
		model: "Advanced 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Servicios incluidos",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
];

export const itemsModelosDetallesOferta = [
	{
		id: 1,
		image: "/images/audi-nuevo-a3-oferta.png",
		preTitle: "Nuevo Audi Q3 Sportback Advance",
		title: "Por 250€/mes",
		price: "En 36 cuotas",
		priceNote: "Con Easy Renting. Entrada: 10.000€. \n" + "IVA no incluido.",
		model: "Advanced 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
			{
				text: "Contratar online",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Servicios incluidos",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento de Serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento de Serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
	// {
	// 	id: 2,
	// 	image: "/images/audi-nuevo-a3-oferta-2.png",
	//	preTitle: "Nuevo Audi Q3 Sportback Advance",
	// 	title: "Por 250€/mes",
	// 	price: "En 36 cuotas",
	// 	priceNote: "Con Easy Renting. Entrada: 10.000€. \n" + "IVA no incluido.",
	// 	model: "Advanced 35 TDI 120 kW (163 CV) S tronic",
	// 	buttons: [
	// 		{
	// 			text: "Solicitar oferta",
	// 			url: "#",
	// 		},
	// 		{
	// 			text: "Contratar online",
	// 			url: "#",
	// 		},
	// 	],
	// 	featuresList: [
	// 		{
	// 			title: "Servicios incluidos",
	// 			infoList: [
	// 				{
	// 					value: "Asistencia en carretera.",
	// 				},
	// 				{
	// 					value: "Seguro a todo riesgo sin franquicia.",
	// 				},
	// 				{
	// 					value: "Mantenimiento en Red Oficial Audi.",
	// 				},
	// 				{
	// 					value: "Reparaciones con recambios originales.",
	// 				},
	// 			],
	// 		},
	// 		{
	// 			title: "Equipamiento de Serie",
	// 			infoList: [
	// 				{
	// 					value: "Asistencia en carretera.",
	// 				},
	// 				{
	// 					value: "Seguro a todo riesgo sin franquicia.",
	// 				},
	// 				{
	// 					value: "Mantenimiento en Red Oficial Audi.",
	// 				},
	// 				{
	// 					value: "Reparaciones con recambios originales.",
	// 				},
	// 			],
	// 		},
	// 		{
	// 			title: "Equipamiento de Serie",
	// 			infoList: [
	// 				{
	// 					value: "Asistencia en carretera.",
	// 				},
	// 				{
	// 					value: "Seguro a todo riesgo sin franquicia.",
	// 				},
	// 				{
	// 					value: "Mantenimiento en Red Oficial Audi.",
	// 				},
	// 				{
	// 					value: "Reparaciones con recambios originales.",
	// 				},
	// 			],
	// 		},
	// 		{
	// 			title: "Condiciones",
	// 			infoList: [
	// 				{
	// 					value: "Asistencia en carretera.",
	// 				},
	// 				{
	// 					value: "Seguro a todo riesgo sin franquicia.",
	// 				},
	// 				{
	// 					value: "Mantenimiento en Red Oficial Audi.",
	// 				},
	// 				{
	// 					value: "Reparaciones con recambios originales.",
	// 				},
	// 			],
	// 		},
	// 	],
	// },
];

// OPCION FUTURO

export const itemsModelosDetallesGridOpcionFuturo = [
	{
		id: 1,
		image: "/images/modelo-detalle-01.png",
		preTitle: "Nuevo Audi Q3 Sportback Advance",
		title: "Por 250€/mes",
		price: "En 36 cuotas.",
		priceExtra: "TAE 9,8%",
		priceNote: "Entrada: 10.000€. Cuota Final:8.000€",
		model: "Motor: 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Equipamiento de serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento de serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
	{
		id: 2,
		image: "/images/modelo-detalle-02.png",
		preTitle: "Nuevo Audi Q3 Sportback Advance",
		title: "Por 300€/mes",
		price: "En 32 cuotas.",
		priceExtra: "TAE 9,36%",
		priceNote: "Entrada: 10.000€. Cuota Final:8.000€",
		model: "Motor: 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Equipamiento de serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento de serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
	{
		id: 3,
		image: "/images/modelo-detalle-03.png",
		preTitle: "Nuevo Audi Q3 Sportback Advance",
		title: "Por 350€/mes",
		price: "En 36 cuotas.",
		priceExtra: "TAE 9,36%",
		priceNote: "Entrada: 10.000€. Cuota Final:8.000€",
		model: "Motor: 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Equipamiento de serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento de serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
];

export const itemsModelosDetallesGridDosOpcionFuturo = [
	{
		id: 1,
		image: "/images/modelo-detalle-01.png",
		preTitle: "Nuevo Audi Q3 Sportback Advance",
		title: "Por 250€/mes",
		price: "En 36 cuotas.",
		priceExtra: "TAE 9,8%",
		priceNote: "Entrada: 10.000€. Cuota Final:8.000€",
		model: "Motor: 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Equipamiento de serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento de serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
	{
		id: 2,
		image: "/images/modelo-detalle-02.png",
		preTitle: "Nuevo Audi Q3 Sportback Advance",
		title: "Por 300€/mes",
		price: "En 32 cuotas.",
		priceExtra: "TAE 9,36%",
		priceNote: "Entrada: 10.000€. Cuota Final:8.000€",
		model: "Motor: 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Equipamiento de serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento de serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
];

export const itemsModelosDetallesOfertaOpcionFuturo = [
	{
		id: 1,
		image: "/images/audi-nuevo-a3-oferta.png",
		preTitle: "Nuevo Audi Q3 Sportback Advance",
		title: "Por 250€/mes",
		price: "En 36 cuotas.",
		priceExtra: "TAE 9,8%",
		priceNote: "Entrada: 10.000€. Cuota Final:8.000€",
		model: "Motor: 35 TDI 120 kW (163 CV) S tronic",
		buttons: [
			{
				text: "Solicitar oferta",
				url: "#",
			},
		],
		featuresList: [
			{
				title: "Equipamiento de Serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Equipamiento de Serie",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
			{
				title: "Condiciones",
				infoList: [
					{
						value: "Asistencia en carretera.",
					},
					{
						value: "Seguro a todo riesgo sin franquicia.",
					},
					{
						value: "Mantenimiento en Red Oficial Audi.",
					},
					{
						value: "Reparaciones con recambios originales.",
					},
				],
			},
		],
	},
];
